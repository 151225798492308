import React from "react"
import Layout from "../components/Layout"
import { AboutText, FinePrint } from '../components/StyledText'

export const Head = () => (
  <>
       <title>ZipTips Privacy</title>
      <meta name="description" content="ZipTips privacy policy" />
      </>
)
const About = () => (
   <Layout navColor="#131313" background="#131313">
    <AboutText>
      <FinePrint>
        <h1>Privacy Policy</h1>
        <p>Last updated September 29th, 2020</p>
        <br/>
        <p>Through the ZipTips.io website and iOS application, Beachead Technologies, Inc. ("Beachead Technologies", "us", "we" or "our") may provide you with access to varied user-generated and other content and interactive and informational resources (collectively, "Services"). This Privacy Policy sets forth our policies with respect to information including personally identifiable data ("Personal Data") and other information that is collected from you when using the Services. This Privacy Policy is incorporated by reference in the Beachead Technologies Terms of Use, which govern your use of the Services and can be found at <a href="http://www.ZipTips.io/terms">www.ZipTips.io/terms</a>.
        </p>
        <h1>Information We Collect</h1>
        <p>When you interact with us through the Services, we may collect Personal Data and other information from you, as further described below: </p>

        <p>Personal Data That You Provide Through the Services: We collect Personal Data from you when you voluntarily choose to provide such information such as when you contact us with inquiries, ask a developer a question, register for access to the Services or use certain Services. Wherever we collect Personal Data we make an effort to provide a link to this Privacy Policy. By voluntarily providing us with Personal Data, you are consenting to our use of it in accordance with this Privacy Policy. If you provide Personal Data through the Services, you acknowledge and agree that such Personal Data may be transferred from your current location to our offices and servers or those of our affiliates or contractors located in the United States and other countries.</p>
        <p>We will NEVER sell your personal data to any Company or individual. </p>

        <h1>Location Data </h1>
        <p>We collect your location data through MixPanel. This helps us understand where our users are visiting from and what they maybe interested in.</p>

        <h1>Other Information </h1>

        <p>In providing the Services, we may use Apple’s Sign in and cookies and localstorage on your iOS device. A cookie is a piece of information that the computer or computers that host our Services give to your browser when you access the Services. Our cookies help provide additional functionality to the Services and help us analyze usage of the Services more accurately. For instance, our Services may set a cookie on your browser that keeps you from needing to remember and then enter a password more than once during a visit to the site. On most web browsers, you will find a "help" section on the toolbar. Please refer to this section for information on how to receive notification when you are receiving a new cookie and how to turn cookies off. We recommend that you leave cookies turned on because they allow you to take advantage of some of the Services' features. </p>

        <h1>Our Use of Your Personal Data and Other Information</h1>
        <p>We will use the Personal Data you provide in a manner that is consistent with this Privacy Policy. If you provide Personal Data for a certain reason, we may use the Personal Data in connection with the reason for which it was provided. For instance, if you contact us by e-mail, we will use the Personal Data you provide to answer your question or resolve your problem. Also, if you provide Personal Data in order to obtain access to the Services, we will use your Personal Data to provide access to such services and to monitor your use of such services. We may also use your Personal Data and other personally non-identifiable information collected through the Services to help us improve the content and functionality of the Services, to better understand our users and to improve the Services. We may use this information to contact you in the future to tell you about services we believe will be of interest to you. </p>

        <p>We will communicate with you through email and push notifications via your iOS devices. These communications may include a series of welcome emails which help inform you about various features of the Services. Please be aware that you will always receive certain emails from us related to the proper functioning of your account. </p>

        <h1>Our Disclosure of Your Personal Data and Other Information</h1>

        <p>There are certain circumstances in which we may share your Personal Data with certain third parties without further notice to you, including as set forth below: </p>

        <p>Service Providers, Agents and Related Third Parties: We sometimes hire other companies or individuals to perform certain business-related functions. Examples include mailing information, maintaining databases and processing payments. When we employ another company to perform a function of this nature, we may need to provide them with access to certain Personal Data. However, we only provide them with the information that they need to perform their specific function, and these third party service providers will only use your Personal Data to perform the services requested by us. </p>

        <p>Legal Requirements: We may also disclose your Personal Data if required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation, (ii) protect and defend our rights or property, (iii) act in urgent circumstances to protect the personal safety of users of the Services or the public, or (iv) protect against legal liability.</p>

        <h1>Your Choices</h1>
        <p>You can use the Services without providing any Personal Data, however, you may not be able to use certain features of the Services. </p>

        <p>When You sign up for our Services, we ask you to provide Personal Data. If we want to use this Personal Data in a manner different than the purpose for which it was collected, we will ask for your consent prior to doing so. If we propose to use Personal Data for any purposes other than those described in this Policy and/or in the specific notice provided when you sign up for a certain component of our Services, we will offer you an effective way to opt out of our use of your Personal Data for those other purposes.</p>

        <h1>Exclusions</h1>
        <p>This Privacy Policy shall not apply to any unsolicited information you provide to us. This includes, but is not limited to, information posted to any public areas of our iOS application or web site, such as creating a Tip (collectively, "Public Data"), any ideas for new products or modifications to existing services, and other unsolicited submissions (collectively, "Unsolicited Information"), including all User Content, as that term is defined in our Terms of Use, that by its nature is accessible or intended to be accessible to other users of the Services. All such Unsolicited Information shall be deemed to be non-confidential and we shall be free to reproduce, use, disclose, and distribute such Unsolicited Information to others without limitation or attribution. Please also note that any Personal Data or other information you provide to be displayed in your publicly available online profile will be displayed for others to see and thus will not be subject to the terms of this Privacy Policy. We cannot and will not be responsible for what third parties do with any Personal Data or other information that you choose to make publicly available. As such, we advise that you exercise great care in determining what information you choose to make publicly available through the Services.</p>

        <h1>Children</h1>
        <p>We do not knowingly collect Personal Data from children under the age of 13 and do not target our websites to children under 13. If you are under thirteen, please do not submit any Personal Data through the Services. We encourage parents and legal guardians to monitor their children's Internet usage and to help enforce our Privacy Policy by instructing their children to never provide Personal Data through any of our Services without their permission. If you have reason to believe that a child under the age of 13 has provided Personal Data to us, please contact us, and we will endeavor to delete that information from our databases.</p>

        <h1>Links to Other Websites</h1>
        <p>This Privacy Policy applies only to the Services. The Services may frame or contain references or links to other websites not operated or controlled by us (the "Third Party Services"). The policies and procedures we described here do not apply to the Third Party Services. The links from the Services do not imply our review or endorsement of the Third Party Services. We suggest contacting those sites directly for information on their privacy policies.</p>

        <h1>Security</h1>
        <p>We take reasonable steps to protect the Personal Data provided via the Services from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. These steps include internal reviews of our data collection, storage and processing practices and security measures, as well as physical security measures to guard against unauthorized access to systems where we store Personal Data. However, no Internet or e-mail transmission is ever fully secure or error free. In particular, e-mail sent to or from the Services may not be secure. Therefore, you should therefore take special care in deciding what information you send to us via e-mail. Please keep this in mind when disclosing any Personal Data to us via the Internet. </p>

        <p>We restrict access to Personal Data to our employees, contractors and agents who need to know that information in order to operate, develop or improve our Services. These individuals are bound by confidentiality obligations and may be subject to discipline if they fail to meet those obligations.</p>

        <h1>Data Integrity</h1>
        <p>We process Personal Data only for the purposes for which it was collected and in accordance with this Policy or any applicable service-specific privacy notice. We review our data collection, storage and processing practices to ensure that we only collect, store and process the Personal Data needed to provide or improve our Services. We take reasonable steps to ensure that the Personal Data we process is accurate, complete, and current, but we depend on our users to update or correct their Personal Data whenever necessary.</p>

        <h1>Enforcement</h1>
        <p>We regularly review our compliance with this Policy. Please feel free to direct any questions or concerns regarding this Policy or our treatment of Personal Data by contacting us through this web site. When we receive formal written complaints, it is our policy to contact the complainant regarding his or her concerns.</p>

        <h1>Changes to Our Privacy Policy</h1>
        <p>The Services we offer may change from time to time. As a result, at times it may be necessary for us to make changes to this Privacy Policy. If the changes are material, we’ll let you know by email or posting a notice on the website before the changes go into effect. The notice will designate a reasonable amount of time (the “Notice Period”) after which the new privacy policy will go into effect for all users. If you don’t agree to the new privacy policy, please delete your account within the Notice Period. If you do not delete your account within the Notice Period, your content and use of the ZipTips.io website and iOS application will be subject to the new privacy policy going forward. This Privacy Policy was last updated on the date indicated above.</p>

        <h1>Access to Information</h1>
        <p>We strive to keep your Personal Data accurately recorded. Through the Services, we provide you with access to update your profile and revise Personal Data previously submitted to the Services.</p>

        <h1>Contacting Us</h1>
        <p>If you have any additional questions or concerns about this Policy or our information practices, please feel free to contact us at any time at <a href="mailto:hello@ZipTips.io">hello@ZipTips.io</a></p>

        <p>&nbsp;</p>

        <p>Address any questions to:</p><p>&nbsp;</p>
        <p>Geoffrey Brookins</p>
        <p>Beachead Technologies, Inc.</p>
        <p>185 West End Avenue, Suite 26F</p>
        <p>New York, NY 10023</p>
        <p><a href="mailto:hello@ZipTips.io">hello@ZipTips.io</a></p>

      </FinePrint>
    </AboutText>
  </Layout>
)

export default About;